var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.qrcode)
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };